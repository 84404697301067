import React from 'react';
import styled from 'styled-components';

import blue from '@material-ui/core/colors/blue';

const PopupArrowContainer = styled.div`
  width: 15px;
  margin: 0 auto;
`;

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-top: 20px solid ${blue[500]};
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
`;
const PopupArrow = () => (
  <PopupArrowContainer>
    <ArrowDown />
  </PopupArrowContainer>
);

export default PopupArrow;
