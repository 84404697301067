import types from './types';

const getOffers = () => ({
  type: types.GET_OFFERS,
});

const getOffer = ({ id }) => ({
  type: types.GET_OFFER,
  payload: { id },
});

const getOfferRequested = ({ id }) => ({
  type: types.GET_OFFER_REQUESTED,
  payload: { id },
});

const getOfferSucceeded = ({ id, offerList }) => ({
  type: types.GET_OFFER_SUCCEEDED,
  payload: { id, offerList },
});

const getOfferFailed = () => ({
  type: types.GET_OFFER_FAILED,
});

const requestOffers = () => ({
  type: types.OFFERS_REQUESTED,
});

const fetchOffers = ({
  offerList,
  offerIds,
  totalCouponsRedeemed,
  totalSavings,
  walletList,
  walletIds,
  loyaltyPlanList,
}) => ({
  type: types.OFFERS_SUCCEEDED,
  payload: {
    offerList,
    offerIds,
    totalCouponsRedeemed,
    totalSavings,
    walletList,
    walletIds,
    loyaltyPlanList,
  },
});

const failedOffers = ({ offerId, message }) => ({
  type: types.OFFERS_FAILED,
  payload: { offerId, message },
});

const addOfferToWallet = ({ offerId }) => ({
  type: types.ADD_OFFER_WALLET,
  payload: {
    offerId,
  },
});

const removeOfferToWallet = ({ offerId, ucc }) => ({
  type: types.REMOVE_OFFER_WALLET,
  payload: {
    offerId,
    ucc,
  },
});

const updateOfferWallet = ({ offerId, actionWallet, ucc }) => ({
  type: types.UPDATE_OFFER_WALLET,
  payload: {
    offerId,
    actionWallet,
    ucc,
  },
});

const getHistory = () => ({
  type: types.GET_HISTORY,
});

const fetchHistory = ({ historyList, historyIds }) => ({
  type: types.HISTORY_SUCCEEDED,
  payload: { historyList, historyIds },
});

const getLoyaltyPlanUsages = ({ offerId }) => ({
  type: types.GET_LOYALTY_PLAN_USAGES,
  payload: { offerId },
});

const fetchLoyaltyPlanUsages = ({ redeemed, offerId }) => ({
  type: types.GET_LOYALTY_PLAN_USAGES_SUCCEEDED,
  payload: { redeemed, offerId },
});

const setRetailerSelected = ({ retailerId }) => ({
  type: types.SET_RETAILER_SELECTED,
  payload: { retailerId },
});

const getPrintUrl = () => ({
  type: types.GET_PRINT_URL,
});

const requestPrintUrl = () => ({
  type: types.GET_PRINT_URL_REQUESTED,
});

const fetchPrintUrl = ({ url }) => ({
  type: types.GET_PRINT_URL_SUCCEEDED,
  payload: { url },
});

const failedPrintUrl = () => ({
  type: types.GET_PRINT_URL_FAILED,
});

const clearPrintUrl = () => ({
  type: types.GET_PRINT_URL_CLEAN,
});

export default {
  getOffers,
  getHistory,
  requestOffers,
  fetchOffers,
  failedOffers,
  addOfferToWallet,
  removeOfferToWallet,
  updateOfferWallet,
  fetchHistory,
  getLoyaltyPlanUsages,
  fetchLoyaltyPlanUsages,
  setRetailerSelected,
  getPrintUrl,
  requestPrintUrl,
  fetchPrintUrl,
  failedPrintUrl,
  clearPrintUrl,
  getOffer,
  getOfferRequested,
  getOfferSucceeded,
  getOfferFailed,
};
