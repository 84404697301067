const GET_CODE_REDEEM = 'redeem/GET_CODE_REDEEM';
const CODE_REDEEM_REQUEST = 'redeem/CODE_REDEEM_REQUEST';
const CODE_REDEEM_SUCCESS = 'redeem/CODE_REDEEM_SUCCESS';
const CHECK_STATUS_REDEEM = 'redeem/CHECK_STATUS_REDEEM';
const CHECK_STATUS_REDEEM_STOP = 'redeem/CHECK_STATUS_REDEEM_STOP';
const CHECK_STATUS_REDEEM_SUCCESS = 'redeem/CHECK_STATUS_REDEEM_SUCCESS';
const CHECK_STATUS_REDEEM_EXIT = 'redeem/CHECK_STATUS_REDEEM_EXIT';

export default {
  GET_CODE_REDEEM,
  CODE_REDEEM_REQUEST,
  CODE_REDEEM_SUCCESS,
  CHECK_STATUS_REDEEM,
  CHECK_STATUS_REDEEM_STOP,
  CHECK_STATUS_REDEEM_SUCCESS,
  CHECK_STATUS_REDEEM_EXIT,
};
