import * as Sentry from '@sentry/browser';
import localforage from 'localforage';
import { call, put, all, takeLatest } from 'redux-saga/effects';
import config from 'config';

import serviceAccount from 'services/account';
import actions from './actions';
import types from './types';

export function* createAccount() {
  yield put(actions.requestAccount());

  try {
    let token = yield localforage.getItem(config.ACCOUNT_UID);
    if (!token) {
      const response = yield call(serviceAccount.getAccount);
      token = response.data.uuid;
      yield localforage.setItem(config.ACCOUNT_UID, token);
    }

    Sentry.configureScope(scope => {
      scope.setUser({ id: token });
    });

    yield serviceAccount.setToken();
    if (process.env.REACT_APP_STAGE === 'production') {
      window.analytics.identify({
        userId: token,
      });
    }
    yield put(actions.setAccount({ uuid: token }));
  } catch (e) {
    yield put(actions.failedAccount({ message: 'Ha habido un error' }));
  }
}

export function* restoreAccount() {
  yield put(actions.requestAccount());
  try {
    const token = yield localforage.getItem(config.ACCOUNT_UID);
    if (token) {
      Sentry.configureScope(scope => {
        scope.setUser({ id: token });
      });
      yield serviceAccount.setToken();
    }
    if (process.env.REACT_APP_STAGE === 'production') {
      window.analytics.identify({
        userId: token,
      });
    }
    yield put(actions.setAccount({ uuid: token }));
  } catch (e) {
    yield put(actions.failedAccount({ message: 'Ha habido un error' }));
  }
}

export function* clearAccount() {
  yield localforage.removeItem(config.ACCOUNT_UID);
}

function* accountSaga() {
  yield all([
    takeLatest(types.CREATE_ACCOUNT, createAccount),
    takeLatest(types.RESTORE_ACCOUNT, restoreAccount),
    takeLatest(types.CLEAR_ACCOUNT, clearAccount),
  ]);
}

export default accountSaga;
