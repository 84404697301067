import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';
import { Redeemed } from 'models';

const getState = state => state.redeem;
getState.selectorName = 'getRedeemState';

const getFetching = createSelector(
  [getState],
  state => state.get('isFetching'),
);
getFetching.selectorName = 'getRedeemFetching';

const getCode = createSelector(
  [getState],
  state => state.get('code'),
);

const getRedeemStatusByCode = createSelector(
  getState,
  redeem =>
    memoize(({ code }) => redeem.getIn(['redeemedByCode', code.toString()]) || new Redeemed()),
);

export default {
  getFetching,
  getCode,
  getRedeemStatusByCode,
};
