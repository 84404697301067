const GET_RETAILERS = 'retailers/GET_RETAILERS';
const RETAILERS_REQUESTED = 'retailers/RETAILERS_REQUESTED';
const RETAILERS_SUCCEEDED = 'retailers/RETAILERS_SUCCEEDED';
const RETAILERS_FAILED = 'retailers/RETAILERS_FAILED';
const STORES_SUCCEEDED = 'retailers/STORES_SUCCEEDED';

export default {
  GET_RETAILERS,
  RETAILERS_REQUESTED,
  RETAILERS_SUCCEEDED,
  RETAILERS_FAILED,
  STORES_SUCCEEDED,
};
