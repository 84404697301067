import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import Routes from 'containers/Routes';
import FormOrderProvider from 'contexts/FromOrderProvider';

import InstallPWA from 'components/InstallPWA';

import { actions as accountActions } from 'store/modules/account';

class App extends React.Component {
  static propTypes = {
    restoreAccount: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { restoreAccount } = this.props;
    restoreAccount();
  }

  render() {
    return (
      <FormOrderProvider>
        <Fragment>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Vales y Cupones</title>
          </Helmet>
          <Routes />
          <InstallPWA />
        </Fragment>
      </FormOrderProvider>
    );
  }
}

const mapDispatchToProps = {
  restoreAccount: accountActions.restoreAccount,
};

const connected = connect(
  null,
  mapDispatchToProps,
);

export default connected(App);
