import { registerSelectors } from 'reselect-tools';

import reducer from './reducer';
import selectors from './selectors';
import actions from './actions';
import types from './types';
import sagas from './sagas';

registerSelectors(selectors);
export { selectors, actions, types, sagas };
export default reducer;
