import types from './types';

const getCode = () => ({
  type: types.GET_CODE_REDEEM,
});

const requestCode = () => ({
  type: types.CODE_REDEEM_REQUEST,
});

const fetchCode = ({ code }) => ({
  type: types.CODE_REDEEM_SUCCESS,
  payload: { code },
});

const checkStatusRedeem = ({ code }) => ({
  type: types.CHECK_STATUS_REDEEM,
  payload: { code },
});

const checkStatusRedeemSuccess = ({ code, success, saved }) => ({
  type: types.CHECK_STATUS_REDEEM_SUCCESS,
  payload: { code, success, saved },
});

const checkStatusRedeemStop = () => ({
  type: types.CHECK_STATUS_REDEEM_STOP,
});

const checkStatusRedeemExit = () => ({
  type: types.CHECK_STATUS_REDEEM_EXIT,
});

export default {
  getCode,
  requestCode,
  fetchCode,
  checkStatusRedeem,
  checkStatusRedeemSuccess,
  checkStatusRedeemStop,
  checkStatusRedeemExit,
};
