import React from 'react';
import { Router } from 'react-router';
import { Switch, Redirect, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import Loading from 'components/Loading';
import history from 'store/history';

import SigninRoute from './SigninRoute';
import PrivateRoute from './PrivateRoute';

const Login = Loadable({
  loader: () => import('views/Login'),
  loading: Loading,
});

const InitData = Loadable({
  loader: () => import('containers/InitData'),
  delay: 400,
  loading: Loading,
});

const OfferDetail = Loadable({
  loader: () => import('views/OfferDetail'),
  delay: 400,
  loading: Loading,
});

// Views

const Routes = () => (
  <Router history={history} basename="/">
    <Switch>
      <Route path="/offer/:offerId" component={OfferDetail} />
      <Redirect exact from="/" to="/login" />
      <SigninRoute path="/login" component={Login} />
      <PrivateRoute component={InitData} />
    </Switch>
  </Router>
);

export default Routes;
