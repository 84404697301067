import * as reduxHelper from 'store/utils/reduxHelper';
import { fromJS } from 'immutable';
import { Offer, LoyaltyPlan } from 'models';
import types from './types';

const initialState = fromJS({
  entities: {
    offer: {},
    loyaltyPlan: {},
  },
  isFetching: true,
  didInvalidate: false,
  isError: false,
  offers: [],
  history: [],
  totalCouponsRedeemed: 0,
  totalSavings: 0,
  retailerSelected: -1,
  print: {
    isError: false,
    isFetching: false,
    url: '',
  },
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_OFFER_REQUESTED: {
      const { id } = action.payload;
      return state.mergeDeep({
        entities: {
          offer: {
            [id]: new Offer({ id, isFetching: true }),
          },
        },
      });
    }

    case types.GET_OFFER_SUCCEEDED: {
      const { id, offerList } = action.payload;
      const offer = offerList[id];
      return state.mergeDeep({
        entities: {
          offer: {
            [id]: new Offer({ ...offer, isFetching: false }),
          },
        },
      });
    }

    case types.OFFERS_REQUESTED:
      return state.merge(fromJS(reduxHelper.applyRequestInfo()));

    case types.OFFERS_SUCCEEDED: {
      /* action.payload: { offers, data } */
      const {
        offerList,
        offerIds,
        totalCouponsRedeemed,
        totalSavings,
        walletList,
        walletIds,
        loyaltyPlanList = {},
      } = action.payload;

      const offersEntities = {};

      offerIds.forEach(offerId => {
        offersEntities[offerId] = new Offer({
          ...offerList[offerId],
          ...walletList[offerId],
          inWallet: walletIds.includes(offerId),
        });
      });

      const loyaltyPlansEntities = {};
      Object.keys(loyaltyPlanList).forEach(loyaltyPlanId => {
        loyaltyPlansEntities[loyaltyPlanId] = new LoyaltyPlan(loyaltyPlanList[loyaltyPlanId]);
      });

      return state.merge(
        fromJS(
          reduxHelper.applyReceiveInfo({
            totalCouponsRedeemed,
            totalSavings,
            offers: offerIds,
            entities: {
              offer: offersEntities,
              loyaltyPlan: loyaltyPlansEntities,
            },
          }),
        ),
      );
    }

    case types.OFFERS_FAILED: {
      const { offerId } = action.payload;
      let newState = state.merge(fromJS({ ...reduxHelper.applyErrorInfo() }));

      if (offerId) {
        newState = newState.updateIn(['entities', 'offer', offerId], offer =>
          offer.merge({
            isProcessingAction: false,
          }),
        );
      }
      return newState;
    }

    case types.ADD_OFFER_WALLET:
    case types.REMOVE_OFFER_WALLET: {
      const { offerId } = action.payload;
      return state.updateIn(['entities', 'offer', offerId], offer =>
        offer.merge({
          isProcessingAction: true,
        }),
      );
    }

    case types.UPDATE_OFFER_WALLET: {
      const { offerId, actionWallet, ucc } = action.payload;
      return state.updateIn(['entities', 'offer', offerId], offer =>
        offer.merge({
          inWallet: actionWallet,
          uccs: actionWallet ? [ucc] : [],
          isProcessingAction: false,
        }),
      );
    }

    case types.HISTORY_SUCCEEDED: {
      const { historyIds } = action.payload;
      return state.mergeDeep(
        fromJS({
          ...reduxHelper.applyReceiveInfo({
            history: historyIds,
          }),
        }),
      );
    }

    case types.GET_LOYALTY_PLAN_USAGES_SUCCEEDED: {
      const { offerId, redeemed } = action.payload;
      const { loyaltyPlanId } = state.getIn(['entities', 'offer', offerId]);
      return state.updateIn(['entities', 'loyaltyPlan', loyaltyPlanId], loyaltyPlan =>
        loyaltyPlan.merge({
          redeemed,
          isFetching: false,
        }),
      );
    }

    case types.SET_RETAILER_SELECTED: {
      const { retailerId } = action.payload;
      return state.set('retailerSelected', parseInt(retailerId, 10));
    }

    case types.GET_PRINT_URL_REQUESTED:
      return state.mergeDeep({
        print: {
          isFetching: true,
          isError: false,
        },
      });

    case types.GET_PRINT_URL_SUCCEEDED: {
      const { url } = action.payload;
      return state.mergeDeep({
        print: {
          isFetching: false,
          url,
        },
      });
    }

    case types.GET_PRINT_URL_FAILED:
      return state.mergeDeep({
        print: {
          isFetching: false,
          isError: true,
        },
      });

    default:
      return state;
  }
}
