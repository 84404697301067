import { Record } from 'immutable';

const RedeemedRecord = Record({
  relatedCode: '',
  success: false,
  saved: 0,
});

class Redeemed extends RedeemedRecord {}

export default Redeemed;
