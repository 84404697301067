import { call, put, all, takeLatest, race, take, delay } from 'redux-saga/effects';

import serviceRedeem from 'services/redeem';
import actions from './actions';
import types from './types';

export function* pollCheckStatusRedeemCode({ code }) {
  while (true) {
    try {
      const { success, saved } = yield call(serviceRedeem.checkStatusRedeemedCoupons, { code });
      if (success) {
        yield put(actions.checkStatusRedeemSuccess({ code, success, saved }));
        yield put(actions.checkStatusRedeemStop());
        return;
      }
      yield call(delay, 4000);
    } catch (err) {
      // yield put(getDataFailureAction(err));
    }
  }
}

export function* redeemCouponsWithCode() {
  yield put(actions.requestCode());
  try {
    const { code } = yield call(serviceRedeem.redeemCouponsWithCode);
    yield put(actions.fetchCode({ code }));
    yield put(actions.checkStatusRedeem({ code }));
  } catch (error) {
    //
  }
}

export function* checkStatusRedeem(action) {
  const {
    payload: { code },
  } = action;
  yield race([call(pollCheckStatusRedeemCode, { code }), take(types.CHECK_STATUS_REDEEM_STOP)]);
}

export function* checkStatusExit() {
  yield put(actions.checkStatusRedeemStop());
}

function* redeemSaga() {
  yield all([
    takeLatest(types.GET_CODE_REDEEM, redeemCouponsWithCode),
    takeLatest(types.CHECK_STATUS_REDEEM, checkStatusRedeem),
    takeLatest(types.CHECK_STATUS_REDEEM_EXIT, checkStatusExit),
  ]);
}

export default redeemSaga;
