const CREATE_ACCOUNT = 'account/CREATE';
const RESTORE_ACCOUNT = 'account/RESTORE';
const REQUEST_ACCOUNT = 'account/REQUEST';
const SUCCESS_ACCOUNT = 'account/SUCCESS';
const FAILED_ACCOUNT = 'account/FAILED';
const CLEAR_ACCOUNT = 'account/CLEAR';

export default {
  CREATE_ACCOUNT,
  RESTORE_ACCOUNT,
  REQUEST_ACCOUNT,
  SUCCESS_ACCOUNT,
  FAILED_ACCOUNT,
  CLEAR_ACCOUNT,
};
