import { combineReducers } from 'redux';
import { snackbarReducer } from 'material-ui-snackbar-redux';

// Import reducers
import offers from './modules/offers';
import retailers from './modules/retailers';
import account from './modules/account';
import redeem from './modules/redeem';

const rootReducer = combineReducers({
  snackbar: snackbarReducer,
  offers,
  retailers,
  account,
  redeem,
});

export default rootReducer;
