import { createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    title: {
      fontSize: '40px',
      lineHeight: '40px',
      color: '#323232',
    },
  },
  shadows: [],
});

export default theme;
