import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import { MuiThemeProvider } from '@material-ui/core/styles';

import ReduxWrapper from 'containers/ReduxWrapper';
import App from 'containers/App';

import theme from 'styles/theme';
import GlobalStyles from 'styles/general';
import Typographies from 'styles/typography';

import I18nProvider from 'contexts/I18nProvider';

import languages from 'static/languages';
import registerServiceWorker from './registerServiceWorker';
import config from './config';

Sentry.init({
  dsn: 'https://b7902c0ae9fa44d6a588e0699dbf2753@sentry.io/1218125',
  release: config.version,
  environment: process.env.NODE_ENV,
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <I18nProvider languages={languages}>
      <ReduxWrapper>
        <App />
      </ReduxWrapper>
      <GlobalStyles />
      <Typographies />
    </I18nProvider>
  </MuiThemeProvider>,
  document.getElementById('root'),
);
registerServiceWorker();
