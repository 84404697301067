import types from './types';

const createAccount = () => ({
  type: types.CREATE_ACCOUNT,
});

const restoreAccount = () => ({
  type: types.RESTORE_ACCOUNT,
});

const requestAccount = () => ({
  type: types.REQUEST_ACCOUNT,
});

const failedAccount = () => ({
  type: types.FAILED_ACCOUNT,
});

const setAccount = ({ uuid }) => ({
  type: types.SUCCESS_ACCOUNT,
  payload: {
    uuid,
  },
});

const clearAccount = () => ({
  type: types.CLEAR_ACCOUNT,
});

export default {
  createAccount,
  restoreAccount,
  requestAccount,
  failedAccount,
  setAccount,
  clearAccount,
};
