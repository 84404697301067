import { schema } from 'normalizr';
import { OFFER_KEY_ID } from 'models/Offer';
import loyaltyPlanSchema from './loyaltyPlanSchema';
import retailerSchema from './retailerSchema';

const offerSchema = new schema.Entity(
  'offer',
  {
    loyaltyPlan: loyaltyPlanSchema,
    retailers: [retailerSchema],
  },
  { idAttribute: OFFER_KEY_ID },
);

export default offerSchema;
