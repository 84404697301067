import { normalize } from 'normalizr';
import api from 'services/api';
import { offerSchemaResponse, walletSchemaResponse, offerSchema } from 'schemas';

const getList = async () => {
  const { data } = await api.get('/v1/offer');
  const normalizrData = normalize(data, offerSchemaResponse);
  const { offer, loyaltyPlan } = normalizrData.entities;
  return {
    offerList: offer,
    loyaltyPlanList: loyaltyPlan,
    offerIds: normalizrData.result,
  };
};

const getWallet = async () => {
  const { data } = await api.get('/v1/wallet');
  const normalizrData = normalize(data, walletSchemaResponse);
  const { totalCouponsRedeemed, totalSavings, coupon: walletIds } = normalizrData.result;
  return {
    totalCouponsRedeemed,
    totalSavings,
    walletList: normalizrData.entities.offer || [],
    walletIds,
  };
};

const getHistory = async () => {
  const { data } = await api.get('/v1/wallet/offer/redeemed');
  const normalizrData = normalize(data, walletSchemaResponse);
  const { coupon: historyIds } = normalizrData.result;
  return {
    historyList: normalizrData.entities.offer || [],
    historyIds,
  };
};

const addOfferWallet = async ({ offerId }) => {
  try {
    const {
      data: { ucc },
    } = await api.post(`/v1/wallet/offer/${offerId}`);
    if (ucc) {
      return { ucc };
    }
    throw new Error();
  } catch (error) {
    throw new Error(error);
  }
};

const getOfferById = async ({ offerId }) => {
  const { data } = await api.get(`/v1/offer/${offerId}`);
  const {
    entities: { offer },
  } = normalize(data, offerSchema);
  return {
    offer,
  };
};

const removeOfferWallet = ({ ucc }) => api.delete(`/v1/wallet/offer/ucc/${ucc}`);

const getUsagesPromoByOffer = async ({ offerId }) => {
  const {
    data: { redeemed },
  } = await api.get(`/v1/loyaltyplan/${offerId}`);
  return {
    redeemed,
  };
};

const getPrintUrl = async () => {
  const {
    data: { url },
  } = await api.post('/v1/wallet/printcoupons');
  return {
    url,
  };
};

export default {
  getList,
  getWallet,
  addOfferWallet,
  removeOfferWallet,
  getHistory,
  getUsagesPromoByOffer,
  getPrintUrl,
  getOfferById,
};
