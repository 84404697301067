import styled from 'styled-components';

import blue from '@material-ui/core/colors/blue';

import shareIconIos from 'static/images/share-ios.png';

const PopupText = styled.div`
  background: ${blue[500]};
  text-align: center;
  padding: 10px;
  color: #fff;
  font-size: 12px;
`;

const Title = styled.h5`
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 0 10px;
  font-size: 12px;
`;

const Description = styled.p`
  margin: 0;
`;

const ShareIcon = styled.div`
  background: url(${shareIconIos});
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: bottom;
  margin: 0 5px;
`;

PopupText.Title = Title;
PopupText.Description = Description;
PopupText.ShareIcon = ShareIcon;
export default PopupText;
