import React from 'react';
import { FromOrderContext } from 'contexts/FromOrderProvider';

function withFromOrder(Component) {
  return function FromOrderComponent(props) {
    return (
      <FromOrderContext.Consumer>
        {({ orderId, changeFromOrder }) => (
          <Component
            {...props}
            fromOrder={{
              orderId,
              changeFromOrder,
            }}
          />
        )}
      </FromOrderContext.Consumer>
    );
  };
}

export default withFromOrder;
