import types from './types';

const getRetailers = () => ({
  type: types.GET_RETAILERS,
});

const requestRetailers = () => ({
  type: types.RETAILERS_REQUESTED,
});

const fetchRetailers = ({ retailerList, retailerIds }) => ({
  type: types.RETAILERS_SUCCEEDED,
  payload: {
    retailerList,
    retailerIds,
  },
});

const fetchStores = ({ storeList, storeIds }) => ({
  type: types.STORES_SUCCEEDED,
  payload: {
    storeList,
    storeIds,
  },
});

const failedRetailers = ({ message }) => ({
  type: types.RETAILERS_FAILED,
  payload: message,
});

export default {
  getRetailers,
  requestRetailers,
  fetchRetailers,
  failedRetailers,
  fetchStores,
};
