import { call, put, all, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { snackbarActions as snackbar } from 'material-ui-snackbar-redux';
import history from 'store/history';
import { actions as accountActions, types as accountTypes } from 'store/modules/account';
import serviceOffers from 'services/offers';
import actions from './actions';
import types from './types';
import selectors from './selectors';

export function* getHistory() {
  // yield put(actions.requestOffers());
  try {
    const { historyList, historyIds } = yield call(serviceOffers.getHistory);

    yield put(
      actions.fetchHistory({
        historyList,
        historyIds,
      }),
    );
  } catch (e) {
    yield put(actions.failedOffers({ message: 'Ha habido un error' }));
  }
}

export function* getOffer(action) {
  const {
    payload: { id },
  } = action;

  const offersList = yield select(selectors.getOffersList);
  if (offersList.includes(id)) {
    return;
  }

  yield put(actions.getOfferRequested({ id }));
  try {
    const { offer } = yield call(serviceOffers.getOfferById, { offerId: id });
    yield put(actions.getOfferSucceeded({ id, offerList: offer }));
  } catch (error) {
    //
  }
}

export function* getOffers() {
  yield put(actions.requestOffers());

  try {
    const { offerList, offerIds, loyaltyPlanList } = yield call(serviceOffers.getList);

    const { totalCouponsRedeemed, totalSavings, walletList, walletIds } = yield call(
      serviceOffers.getWallet,
    );

    yield getHistory();

    yield put(
      actions.fetchOffers({
        offerList,
        offerIds,
        totalCouponsRedeemed,
        totalSavings,
        walletList,
        walletIds,
        loyaltyPlanList,
      }),
    );
  } catch (e) {
    yield put(accountActions.clearAccount());
    yield put(actions.failedOffers({ message: 'Ha habido un error' }));
  }
}

export function* addOfferWallet(action) {
  const { offerId } = action.payload;
  try {
    const { ucc } = yield call(serviceOffers.addOfferWallet, { offerId });
    yield put(actions.updateOfferWallet({ offerId, actionWallet: true, ucc }));
    yield put(
      snackbar.show({
        message: 'Oferta añadida a Mis Cupones',
        action: 'Ver',
        handleAction: () => {
          history.push('/wallet');
        },
      }),
    );
  } catch (e) {
    yield put(actions.failedOffers({ offerId, message: 'Ha habido un error' }));
  }
}

export function* removeOfferWallet(action) {
  const { offerId, ucc } = action.payload;
  try {
    yield call(serviceOffers.removeOfferWallet, { ucc });
    yield put(actions.updateOfferWallet({ offerId, actionWallet: false }));
  } catch (e) {
    yield put(actions.failedOffers({ message: 'Ha habido un error' }));
  }
}

export function* getLoyaltyPlanUsages(action) {
  const { offerId } = action.payload;
  try {
    const { redeemed } = yield call(serviceOffers.getUsagesPromoByOffer, { offerId });
    yield put(actions.fetchLoyaltyPlanUsages({ offerId, redeemed }));
  } catch (error) {
    //
  }
}

export function* getPrintUrl() {
  yield put(actions.requestPrintUrl());
  try {
    const { url } = yield call(serviceOffers.getPrintUrl);
    yield put(actions.fetchPrintUrl({ url }));
  } catch (error) {
    yield put(actions.failedPrintUrl());
  }
}

function* offerSaga() {
  yield all([
    takeLatest(types.GET_OFFERS, getOffers),
    takeLatest(types.GET_OFFER, getOffer),
    takeEvery(types.ADD_OFFER_WALLET, addOfferWallet),
    takeEvery(types.REMOVE_OFFER_WALLET, removeOfferWallet),
    takeLatest(types.GET_HISTORY, getHistory),
    takeLatest(types.GET_LOYALTY_PLAN_USAGES, getLoyaltyPlanUsages),
    takeLatest(types.GET_PRINT_URL, getPrintUrl),
    takeLatest(accountTypes.SUCCESS_ACCOUNT, getOffers),
  ]);
}

export default offerSaga;
