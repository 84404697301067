const GET_OFFERS = 'offers/GET_OFFERS';
const OFFERS_REQUESTED = 'offers/OFFERS_REQUESTED';
const OFFERS_SUCCEEDED = 'offers/OFFERS_SUCCEEDED';
const OFFERS_FAILED = 'offers/OFFERS_FAILED';
const UPDATE_OFFER_WALLET = 'offers/UPDATE_OFFER_WALLET';
const ADD_OFFER_WALLET = 'offers/ADD_OFFER_WALLET';
const REMOVE_OFFER_WALLET = 'offers/REMOVE_OFFER_WALLET';
const GET_HISTORY = 'offers/GET_HISTORY';
const HISTORY_SUCCEEDED = 'offers/HISTORY_SUCCEEDED';
const GET_LOYALTY_PLAN_USAGES = 'offers/GET_LOYALTY_PLAN_USAGES';
const GET_LOYALTY_PLAN_USAGES_SUCCEEDED = 'offers/GET_LOYALTY_PLAN_USAGES_SUCCEEDED';
const SET_RETAILER_SELECTED = 'offers/SET_RETAILER_SELECTED';
const GET_PRINT_URL = 'offers/GET_PRINT_URL';
const GET_PRINT_URL_REQUESTED = 'offers/GET_PRINT_URL_REQUESTED';
const GET_PRINT_URL_SUCCEEDED = 'offers/GET_PRINT_URL_SUCCEEDED';
const GET_PRINT_URL_FAILED = 'offers/GET_PRINT_URL_FAILED';
const GET_PRINT_URL_CLEAN = 'offers/GET_PRINT_URL_CLEAN';
const GET_OFFER = 'offers/GET_OFFER';
const GET_OFFER_REQUESTED = 'offers/GET_OFFER_REQUESTED';
const GET_OFFER_SUCCEEDED = 'offers/GET_OFFER_SUCCEEDED';
const GET_OFFER_FAILED = 'offers/GET_OFFER_FAILED';

export default {
  GET_OFFERS,
  OFFERS_REQUESTED,
  OFFERS_SUCCEEDED,
  OFFERS_FAILED,
  ADD_OFFER_WALLET,
  REMOVE_OFFER_WALLET,
  UPDATE_OFFER_WALLET,
  GET_HISTORY,
  HISTORY_SUCCEEDED,
  GET_LOYALTY_PLAN_USAGES,
  GET_LOYALTY_PLAN_USAGES_SUCCEEDED,
  SET_RETAILER_SELECTED,
  GET_PRINT_URL,
  GET_PRINT_URL_REQUESTED,
  GET_PRINT_URL_SUCCEEDED,
  GET_PRINT_URL_FAILED,
  GET_PRINT_URL_CLEAN,
  GET_OFFER,
  GET_OFFER_REQUESTED,
  GET_OFFER_SUCCEEDED,
  GET_OFFER_FAILED,
};
