import { Record } from 'immutable';

const RetailerRecord = Record({
  id: null,
  name: '',
  iconUrl: '',
});

class Retailer extends RetailerRecord {
  get image() {
    return this.get('iconUrl');
  }
}

export default Retailer;
