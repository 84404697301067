import { Record } from 'immutable';

const StoreRecord = Record({
  id: null,
  name: '',
  location: {
    lat: null,
    lng: null,
  },
  retailer: null,
  address: '',
});

class Store extends StoreRecord {
  // TODO: update lat, lng
  get lat() {
    return this.location.lng;
  }

  get lng() {
    return this.location.lat;
  }
}

export default Store;
