import { all, fork } from 'redux-saga/effects';

import { sagas as offersSaga } from 'store/modules/offers';
import { sagas as accountSaga } from 'store/modules/account';
import { sagas as retailersSaga } from 'store/modules/retailers';
import { sagas as redeemSaga } from 'store/modules/redeem';

export default function* rootSaga() {
  yield all([fork(offersSaga), fork(accountSaga), fork(retailersSaga), fork(redeemSaga)]);
}
