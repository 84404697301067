import React from 'react';
import PropTypes from 'prop-types';

export const FromOrderContext = React.createContext();

class FromOrderProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    orderId: null,
  };

  changeOrderId = ({ orderId }) => {
    this.setState({ orderId });
  };

  render() {
    const { orderId } = this.state;
    const { children } = this.props;
    const value = {
      orderId,
      changeFromOrder: this.changeOrderId,
    };

    return <FromOrderContext.Provider value={value}>{children}</FromOrderContext.Provider>;
  }
}

export default FromOrderProvider;
