import { normalize } from 'normalizr';
import api from 'services/api';
import { retailerSchemaResponse, storeSchemaResponse } from 'schemas';

const getList = async () => {
  const { data } = await api.get('/v1/retailer');
  const normalizrData = normalize(data, retailerSchemaResponse);
  const { retailer } = normalizrData.entities;
  return {
    retailerList: retailer,
    retailerIds: normalizrData.result,
  };
};

const getStores = async () => {
  const { data } = await api.get('/v1/store');
  const normalizrData = normalize(data, storeSchemaResponse);
  const { store } = normalizrData.entities;
  return {
    storeList: store,
    storeIds: normalizrData.result,
  };
};

export default {
  getList,
  getStores,
};
