import localforage from 'localforage';
import pkg from '../package.json';

localforage.config({
  name: 'vales-y-cupones',
  storeName: 'ecoupon',
});

const generals = {
  version: pkg.version,
  GOOGLE_MAPS_KEY: 'AIzaSyCDyjD_MF7o1dug71D11aJ2J23vNg2WyRI',
  MAPBOX_KEY:
    'pk.eyJ1IjoianplcnBhIiwiYSI6ImNqbmc5cW12ejAwdTEzdm95ZDN2eDFjanUifQ.6vhMo8uUbPT4BQBoQnjpDw',
  walletMode: process.env.REACT_APP_WALLET_MODE,
  initialRoute: process.env.REACT_APP_WALLET_MODE === 'only-wallet' ? '/wallet' : '/offers',
  ACCOUNT_UID: 'Valassis-Wallet-Id',
  INSTALL_PWA_SHOWED: 'INSTALL_PWA_SHOWED',
  generalInstructions: '',
};

const dev = {
  host: 'https://apiwallet.ecupon.es',
};

const prod = {
  host: 'https://apiwalletprod.ecupon.es',
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

const retailers = [
  {
    id: 'lupa',
    name: 'Lupa',
    imageUrl: `${process.env.PUBLIC_URL}/retailers/lupa.jpg`,
    instructions: '',
  },
  {
    id: 'supersol',
    name: 'SuperSol',
    imageUrl: `${process.env.PUBLIC_URL}/retailers/supersol.png`,
    instructions: '',
  },
  {
    id: 'carrefour',
    name: 'Carrefour',
    imageUrl: `${process.env.PUBLIC_URL}/retailers/carrefour.png`,
    instructions: 'YA VA, YA VA',
  },
];

export default {
  ...generals,
  ...config,
  retailers,
};
