import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import config from 'config';
import { selectors as accountSelectors } from 'store/modules/account';
import { compose } from 'recompose';
import withFromOrder from 'hocs/withFromOrder';

const SigninRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !rest.isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: rest.fromOrder.orderId ? '/wallet' : config.initialRoute }} />
      )
    }
  />
);

SigninRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  fromOrder: PropTypes.shape({
    orderId: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: accountSelectors.isAuthenticated(state),
});

const connected = connect(mapStateToProps);

const enhaced = compose(
  connected,
  withFromOrder,
);

export default enhaced(SigninRoute);
