import { createGlobalStyle } from 'styled-components';

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
const vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  const newVh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${newVh}px`);
});

const GlobalStyle = createGlobalStyle`
  html {
    touch-action: manipulation;
  }

  body {
    font-family: "Montserrat";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    overflow-y: hidden;
    overscroll-behavior: contain;
  }

  .snackbar-success {
    height: 56px;

    & > div {
      background: #4CAF50;
      height: 44px;
    }
    span {
      color: #FFF;
      font-weight: bold;
    }
  }
`;

export default GlobalStyle;
