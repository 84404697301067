import { Record } from 'immutable';

const LoyaltyPlanRecord = Record({
  id: null,
  isFetching: true,
  usages: 0,
  redeemed: 0,
  moreInfoUrl: '',
  startOn: '',
  endOn: '',
});

class LoyaltyPlan extends LoyaltyPlanRecord {
  get url() {
    return this.get('moreInfoUrl');
  }
}

export default LoyaltyPlan;
