import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'material-ui-snackbar-redux';
import Fade from '@material-ui/core/Fade';

import configureStore from 'store/configureStore';

const store = configureStore();

const ReduxWrapper = ({ children }) => (
  <Provider store={store}>
    <SnackbarProvider
      SnackbarProps={{
        autoHideDuration: 2500,
        TransitionComponent: Fade,
        classes: { root: 'snackbar-success' },
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      }}
    >
      {children}
    </SnackbarProvider>
  </Provider>
);

ReduxWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReduxWrapper;
