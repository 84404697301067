import * as reduxHelper from 'store/utils/reduxHelper';
import { fromJS } from 'immutable';
import { Redeemed } from 'models';
import types from './types';

const initialState = fromJS(
  reduxHelper.applyReceiveInfo({
    code: 0,
    redeemedByCode: {},
  }),
);

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.CODE_REDEEM_REQUEST:
      return state.merge(fromJS(reduxHelper.applyRequestInfo()));

    case types.CODE_REDEEM_SUCCESS: {
      const { code } = action.payload;
      return state.merge(
        fromJS(
          reduxHelper.applyErrorInfo({
            code,
          }),
        ),
      );
    }

    case types.CHECK_STATUS_REDEEM: {
      const { code } = action.payload;
      return state.mergeDeep({
        redeemedByCode: {
          [code]: new Redeemed({ relatedCode: code }),
        },
      });
    }

    case types.CHECK_STATUS_REDEEM_SUCCESS: {
      const { code, success, saved } = action.payload;
      return state.mergeDeep({
        redeemedByCode: {
          [code]: new Redeemed({ relatedCode: code, saved, success }),
        },
      });
    }

    case types.CHECK_STATUS_REDEEM_EXIT: {
      return state.merge({
        code: 0,
      });
    }

    default:
      return state;
  }
}
