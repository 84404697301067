import styled from 'styled-components';

const PopupContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
`;

export default PopupContainer;
