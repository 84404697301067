import api from 'services/api';

const redeemCouponsWithCode = async () => {
  const {
    data: { code },
  } = await api.post('/v1/wallet/redeemwcode');
  return {
    code,
  };
};

const checkStatusRedeemedCoupons = async ({ code }) => {
  const {
    data: { success, saved },
  } = await api.get(`/v1/wallet/redeemwcode/${code}`);
  return {
    success,
    saved,
  };
};

export default {
  redeemCouponsWithCode,
  checkStatusRedeemedCoupons,
};
