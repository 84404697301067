import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

export const I18nContext = React.createContext();

// Then create a Provider Component
class I18nProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    languages: PropTypes.instanceOf(Object).isRequired,
  };

  state = {
    selectedLanguage: navigator.language.substring(0, 2),
  };

  selectLiteral = (key, defaultValue = '') => {
    const { selectedLanguage } = this.state;
    const { languages } = this.props;
    return get(languages, `[${selectedLanguage}][${key}]`, defaultValue);
  };

  render() {
    const { children, languages } = this.props;
    return (
      <I18nContext.Provider
        value={{
          languages,
          t: this.selectLiteral,
        }}
      >
        {children}
      </I18nContext.Provider>
    );
  }
}

export default I18nProvider;
